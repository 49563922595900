<template>
  <div>
    <div class="container">
      <!-- Headline Section Section start-->
      <div class="row headLineRow d-none d-md-flex">
        <div class="col-1">
          <v-checkbox
              class="pa-0 ma-0"
              :input-value="false"
              v-model="allChecked"
              @click="setAllChecked()"
          >
          </v-checkbox>
        </div>
        <div :class="getClassesHeadLine(headLine.text)" v-for="headLine of headLines" v-bind:key="headLine.text">
          <h3 v-if="headLine.sort" @click="setSorter(headLine)" class="text-h6">
            <a class="pe-1">{{ headLine.text }}
              <v-icon :class="getClassSort(headLine.sort, 'ASC')" size="20px">mdi-arrow-up</v-icon>
              <v-icon :class="getClassSort(headLine.sort, 'DESC')" size="20px">mdi-arrow-down</v-icon>
            </a>
          </h3>
          <h3 v-else class="text-h6">
            <p class="pe-1">{{ headLine.text }}
            </p>
          </h3>
        </div>
      </div>
      <div class="row headLineRow d-flex d-md-none dropdown-example-3 align-content-center">
        <div class="col-10 align-self-center">
          <v-overflow-btn
              @change="setSorterSM()"
              v-model="sortCheckBox"
              class="my-2"
              :items="headLines"
              label="Sortieren nach"
              counter
              item-value="text"
          ></v-overflow-btn>
        </div>
        <div class="col-2 align-self-center"
             @click="setSortStyleSM()">
          <v-icon :class="getClassSort('', 'ASC')" size="20px">mdi-arrow-up</v-icon>
          <v-icon :class="getClassSort('', 'DESC')" size="20px">mdi-arrow-down</v-icon>
        </div>
      </div>

      <!-- Headline Section Section end-->

      <!-- Rows start-->
      <div v-if="rows.length > 0" class="pt-8">
        <div
            :id="'row'+index"
            class="row customerRow pa-3 pt-8 pb-8 pa-md-1"
            v-for="(values, index) of rows" v-bind:key="index"
        >
          <div class="col-1" style="cursor: initial">
            <v-checkbox
                class="pa-0 ma-0"
                :input-value="false"
                v-model="checkedIds[values.id]"
                @click="setCheckedEntries()"
            >
            </v-checkbox>
          </div>
          <div :class="getClassesField(key)"
               v-for="(value, key,i) of values.values" v-bind:key="index +'-'+ i"
               @click="openDetail(values.id)">
            <div v-if="key === 'id'" class="d-none d-sm-flex">
              {{ value }}
              <v-icon color="primary" size="20" class="scale" @click="openEdit(values.id)">
                mdi-lead-pencil
              </v-icon>
            </div>

            <div v-else-if="key === 'created'|| key === 'wedDate' || key=== 'date'" class="d-none d-sm-flex">
              {{ getStringDate(value) }}
            </div>
            <div v-else-if="key === 'email' && value !== ''">

              <a :href="'mailto:'+value">
                <v-icon color="primary" size="20" class="scale">
                  mdi-email
                </v-icon>
              </a>
              {{ value }}
            </div>
            <div v-else-if="key === 'tel' && value !== ''">
              <a :href="'tel:'+value">
                <v-icon color="primary" size="20" class="scale">
                  mdi-phone
                </v-icon>
              </a>
              {{ value }}
            </div>

            <div v-else-if="key === 'category' && value !== '' && value !== null">
              <v-icon color="secondary" size="20" v-if="value.icon">
                {{ value.icon }}
              </v-icon>
              {{ value.name }}
            </div>
            <div v-else-if="key === 'stil' || key === 'services'  && value !== '' && value !== null">
              <span  v-for="(val, index) of value" :key="val.id">
                <span v-if="index !== 0">, </span>
                {{ val.name }}

              </span>
            </div>

            <div v-else-if="key === 'status' && value !== '' && value !== null && entity === 'customer'">
                <span :style="'border-left: 7px solid '+getCustomerStatusColor(value)" class="ps-1">{{ value }}</span>
            </div>
            <span v-else-if="key === 'priceClass'">
              <span v-if="value === 1">€</span>
              <span v-if="value === 2">€€</span>
              <span v-if="value === 3">€€€</span>
            </span>

            <div v-else-if="key === 'stil'">
              <span v-for="(val, index) of value" :key="val">
                <span v-if="index!==0">,</span> {{val}}
              </span>
            </div>
            <div v-else-if="key === 'firstname' ">
              {{ value }}<br>
              <small>{{values.partnerFirstname}}</small>
            </div>
            <div v-else-if="key === 'lastname' ">
              {{ value }}<br>
              <small>{{values.partnerLastname}}</small>
            </div>
            <div v-else-if="key === 'status'&& entity==='invoice'">
              <span v-if="value === 0">In Erstellung</span>
              <span v-if="value === 1">Versendet</span>
              <span v-if="value === 2">Bezahlt</span>
              <span v-if="value === 3">Storniert</span>
            </div>
            <div v-else-if="value !== null && value !== 'null m²' && value !== undefined ">
              {{ value }}
            </div>


          </div>
        </div>

      </div>

      <div v-else class="pt-8">
        <NoResultSnippet
            :text="textNoResults"
        ></NoResultSnippet>
      </div>
    </div>

    <!-- Rows end-->

    <!-- Delete Section Section start-->
    <div class="row editRow pt-6 ps-2 pb-2 mt-8 text-end" v-if="checkedIdList.length > 0">
      <div class="col">

        <span class="align-self-center pe-3" v-if="checkedIdList.length === 1">Einen Eintrag ausgewählt</span>
        <span class="align-self-center pe-3" v-if="checkedIdList.length > 1">{{ checkedIdList.length }} Einträge ausgewählt</span>

        <v-tooltip top color="secondary" v-if="exportEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="me-2 pe-0 ps-0">
                <ExcelExport
                    :sheets="[{ name: 'Export', data: getObjectForExport()}]"
                >
                </ExcelExport>
              </v-btn>
            </span>
          </template>
          Exportieren
        </v-tooltip>

        <v-tooltip top color="secondary" v-if="archiveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="me-2" @click="sureToArchiveDialog=true">
                <v-icon >mdi-archive</v-icon>
              </v-btn>
            </span>
          </template>
          Archivieren
        </v-tooltip>

        <v-tooltip top color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="error" @click="sureToDeleteDialog=true">
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </span>
          </template>
          Löschen
        </v-tooltip>


      </div>
    </div>
    <SureToDelete
        :dialog="sureToDeleteDialog"
        :count="checkedIdList.length"
        @cancel="sureToDeleteDialog = false"
        @sure="deleteEntries(); sureToDeleteDialog = false"
    ></SureToDelete>
    <SureToDelete
        :dialog="sureToArchiveDialog"
        :count="checkedIdList.length"
        :buttonText="'Archivieren'"
        :text="'Bist du sicher, dass du '+checkedIdList.length+' Einträge archivieren möchtest?'"
        @cancel="sureToArchiveDialog = false"
        @sure="archiveEntries(); sureToArchiveDialog = false"
    ></SureToDelete>
    <!-- Delete Section Section end-->
  </div>
</template>

<script>


import {mapGetters} from "vuex";
import {date2String} from "@/helper/helper";

const SureToDelete = () => import("@/components/generalUI/SureToDelete");
const NoResultSnippet = () => import("@/components/generalUI/NoResultSnippet");
const ExcelExport = () => import("@/components/imAndExport/ExcelExport");

export default {
  name: "TableView",
  data() {
    return {
      ceckbox: {},
      currentSort: this.headLines[0].sort,
      sortStyle: 'ASC',
      checkedIds: {},
      checkedIdList: [],
      editDialog: false,
      sortCheckBox: "",
      allChecked: false,
      sureToDeleteDialog: false,
      sureToArchiveDialog: false,
      checkedEntries: []
    }
  },
  components: {SureToDelete, NoResultSnippet,ExcelExport},
  props: {
    /**
     *   List of the headlines
     */
    headLines: Array,
    /**
     *   List of list or objects
     */
    rows: Array,
    textNoResults: String,
    entity: String,
    archiveEnabled: Boolean,
    exportEnabled: {
      type: Boolean,
      default(){
        return true
      }
    }

  },
  computed:{
    ...mapGetters('settings', {
      customerStatusForSelection: 'customerStatusForSelection'
    })
  },
  mounted() {

  },
  methods: {
    getClassesField(key) {
      switch (key) {
        default:
          return "col-12 col-md sm-customerField " + key
      }
    },
    getObjectForExport(){
      const output = this.checkedEntries.map(row => {
        const newRow = {};
        Object.entries(row).forEach(([key, value]) => {
          if (key === "values" || key === 'export') {
            Object.entries(value).forEach(([valKey, valValue]) => {
              newRow[valKey] = valValue;
            });
          } else {
            newRow[key] = value;
          }
        });
        return newRow;
      });

      return output;
    },
    getCustomerStatusColor(name){
      for(let status of this.customerStatusForSelection){
        if(name === status.value){
          return status.color
        }
      }
    },
    getClassesHeadLine(key) {
      switch (key) {
        case "Nr.":
          return "col-1 headLineField " + key
        default:
          return "col headLineField " + key
      }
    },
    setSorterSM() {
      this.sortStyle = 'ASC'
      for (let headLine of this.headLines) {
        if (headLine.text === this.sortCheckBox) {
          this.currentSort = headLine.sort
        }
      }
      this.$store.dispatch('sort/sort', {
        objectArray: this.rows,
        sortStyle: this.sortStyle,
        sortField: this.currentSort,
      })
    },
    setSortStyleSM() {
      if (this.sortStyle === 'DESC') {
        this.sortStyle = 'ASC'
      } else {
        this.sortStyle = 'DESC'
      }
      this.$store.dispatch('sort/sort', {
        objectArray: this.rows,
        sortStyle: this.sortStyle,
        sortField: this.currentSort,
      })
    },
    setSorter(headLine) {
      console.log("rows", this.rows)
      console.log("sortStyle", this.sortStyle)
      console.log("field", this.currentSort)

      if (this.currentSort === headLine.sort) {//switch ab- aufsteigend
        if (this.sortStyle === 'DESC') {
          this.sortStyle = 'ASC'
        } else this.sortStyle = 'DESC'
      } else {
        this.currentSort = headLine.sort
        this.sortStyle = 'ASC'
      }
      this.$store.dispatch('sort/sort', {
        objectArray: this.rows,
        sortStyle: this.sortStyle,
        sortField: this.currentSort,
      })
    },
    getClassSort(sort, sortStyle) {
      if ((sort === this.currentSort || sort === '') && sortStyle === this.sortStyle) {
        return 'primary--text scaled'
      } else {
        return 'd-none'
      }
    },
    getStringDate(date) {
      return date2String(date)
    },
    setCheckedEntries() {
      let checkedIds = []
      let checkedEntries = []
      for (let [id, check] of Object.entries(this.checkedIds)) {
        if (check) {
          checkedIds.push(id)
          for(let row of this.rows){
            if(row.id === id){
              checkedEntries.push(row)
            }
          }
        }
      }
      this.checkedIdList = checkedIds
      this.checkedEntries = checkedEntries
      if (this.checkedIdList.length === this.rows.length) {
        this.allChecked = true
        this.checkedEntries = this.rows
      } else {
        this.allChecked = false
      }
    },
    deleteEntries() {
      this.$emit("deleteEntries", this.checkedIdList)
      this.checkedIdList = []
      this.checkedIds = {}
      this.allChecked = false
    },
    archiveEntries() {
      console.log("test")
      console.log(this.checkedIdList)
      this.$emit('archiveEntries', this.checkedIdList)
      this.checkedIdList = []
      this.checkedIds = {}
      this.allChecked = false
    },
    editCustomers() {
      this.$emit("editCustomers", this.checkedIdList)
      this.checkedIdList = []
      this.checkedIds = {}
    },
    openDetail(id) {
      this.$emit("openDetail", id)
    },
    openEdit(id) {
      this.$emit("openEdit", id)
    },
    openRow(key, i, value) {
      console.log("key")
      console.log(key + i + value)
      this.isEdit = key + i + value
    },
    setAllChecked() {
      if (this.allChecked) {
        for (let values of this.rows) {
          if (!this.checkedIds[values.id]) {
            this.checkedIds[values.id] = true
            this.checkedIdList.push(values.id)
            this.checkedEntries.push(values)
          }
        }
      } else {
        this.checkedIdList = []
        this.checkedIds = {}
        this.checkedEntries = []
      }

    }
  }
}

</script>


<style scoped>

.customerRow {
  border-top: dotted 1px #F1E7E2;
  border-bottom: dotted 1px #F1E7E2;
  transition: 0.4s;
}


.customerRow:hover {
  transform: scale(1.01);
  transition: 0.4s;
  background-color: #F3F4F3 !important;

  cursor: pointer;
}

.eye:hover {
  transform: scale(1.01);
  cursor: pointer;
}

.customerField {
  border-left: dotted 1px #F1E7E2;
  border-right: dotted 1px #F1E7E2;
  overflow: hidden;
}

.headLineField {
  overflow: hidden;
}

.headLineRow {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}

.editRow {
  position: sticky;
  background-color: white;
  bottom: 0;
  z-index: 2;
}


.scaled {
  transform: scale(1.4);
}
</style>
